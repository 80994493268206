import React, { Component, PureComponent } from 'react';
import Helper from '../Helper';
import { Link } from '@reach/router';

export default class CarCard extends PureComponent {
    componentDidUpdate(prevProps) {
console.log(`prevProps`,prevProps)
console.log(`props`,this.props)
    }
    render() {
        const data = this.props.data
        // data.is_reserve = true;
        return (
            <div style={{ position: 'relative' }}>
                <Link to={`/car/${this.props.data.uid}`} className='car-card'>
                    {
                        data.is_reserve ?
                            <div className="ribbon ribbon-top-left"><span>ติดจอง</span></div>
                            : null
                    }
                    <div className='car-card-image' style={{ position: 'relative' }}>
                        {
                            data.status.id == '1' ?
                                <div className='custom-ribbon '><i className="las la-wrench"></i> กำลังซ่อม</div>
                                : null
                        }
                        {
                            data.fileImageCar?.imagePath ?
                                <img src={data.fileImageCar?.thumbnailURL350 || data.fileImageCar?.imagePath} alt={"car"} />
                                : null
                        }
                    </div>
                    <div className='car-card-info'>
                        <div >
                            <div className='d-flex w-100 align-items-center flex-wrap'>
                                <h3>{data.brand}</h3>
                                <div className='ml-auto'>
                                    <small><i className="fa-solid fa-gauge-high"></i>  {Helper.numberFormat(data.mile || 0)} km, {data.year ? `${data.year}` : null}</small>
                                </div>
                            </div>
                            <p>
                                {data.model}
                                {` `}
                                {data.submodel || data.minor ? <><span className="bullet bullet-dot"></span></> : null}
                                {data.minor ? ` ${data.minor}` : null}
                                {data.submodel ? ` ${data.submodel}` : null}
                                {` `}
                            </p>
                        </div>
                        <div className='price'>{Helper.numberFormat(data.sellingPrice)} <small className='text-muted'>บาท</small></div>
                        {/* <hr /> */}
                        {/* <div className='row '>
                            <div className='col-6 col-md-6'>
                                <p className='text-center'>ไมล์</p>
                                <div className='d-flex justify-content-center'><p className='text-bold text-center'><i className="fa-solid fa-gauge-high"></i>  {Helper.numberFormat(data.mile || 0)}</p></div>
                            </div>
                            <div className='col-4 col-md-4'>
                                  <p className='text-center'>สี</p>
                                  <div className='d-flex justify-content-center'><p className='text-bold text-center'>{data.color}</p></div>
                                </div>
                            <div className='col-6 col-md-6'>
                                <p className='text-center'>ปี</p>
                                <div className='d-flex justify-content-center'>
                                    <p className='text-bold text-center'>
                                        <i className="fa-regular fa-calendar-days"></i> {data.year ? `${data.year}` : null}
                                    </p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </Link>
            </div>
        )
    }
}
